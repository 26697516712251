
/* :: 1.0 Import Fonts */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
/* :: 2.0 Import All CSS */
@import url(../css/bootstrap.min.css);

/* :: 3.0 Base CSS */

* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

body {
   }
html{
     overflow-x: hidden !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #323232;
  font-weight: 600; }
h2{
    font-weight: 700;
}
p {
  color: #000;
  font-size: 14px;
  line-height: 2;
  font-weight: 600; }


ul,
ol {
  margin: 0; }
  ul li,
  ol li {
    list-style: none; }

 
.phone-call .phone{
    position: fixed;
    display: block;
    bottom: 92px !important;
    right: 28px !important;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      background-color: rgb(64, 184, 242, 1);
      color: #fff;
      transform: all .5s ease;
      z-index: 999;
      border-radius: 50%;
      width: 45px;
      height: 45px;
}
a{
  text-decoration: none;
}
a:hover{
  text-decoration: none;
}
.phone-call .phone::before{
  content: "";
  position: absolute;
  z-index: -1;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 51px;
  height: 51px;
  background-color: rgb(64, 184, 242, 1);
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms linear infinite;
  animation: pulse-border 1500ms linear infinite;
  animation-delay: 1s;
}
.phone-call .phone::after{
  content: "";
  position: absolute;
  z-index: -1;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  background-color: rgb(64, 184, 242, 1);
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms linear infinite;
  animation: pulse-border 1500ms linear infinite;
  
}
.whats-app .phone{
  background-color: #25D366;
  bottom: 160px !important;
  right: 28px !important;
}
.whats-app .phone::before,
.whats-app .phone::after{
  background-color: #25D366;

}
@keyframes pulse-border{
    0%{
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
     100%{
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
}

.last-container{
bottom: 0;
left: 0;
width: 100%;
height: 6vh;
  position: fixed;
  z-index: 999;
  display: none;
}
.last-container a{
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
.last-container .log{
  width: 50%;
  height: 100%;
 align-items: center;
 display: flex;
 justify-content: center;
  background-color: #25D366;
}


img {
  height: auto;
  max-width: 100%; 
    
}
.property-thumb img{
    border-radius: 10px;
}
.heading-row{
    height: 10vh;
    width: 100%;
}
.mt-15 {
  margin-top: 15px; }

.mt-30 {
  margin-top: 30px; }

.mt-50 {
  margin-top: 50px; }

.mt-70 {
  margin-top: 70px; }

.mt-100 {
  margin-top: 100px; }

.mb-15 {
  margin-bottom: 15px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-50 {
  margin-bottom: 50px; }

.ml-15 {
  margin-left: 15px; }

.ml-30 {
  margin-left: 30px; }

.mr-15 {
  margin-right: 15px; }

.mr-30 {
  margin-right: 30px; }

.mb-70 {
  margin-bottom: 70px; }

.mb-100 {
  margin-bottom: 30px; }

.bg-img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }

.bg-transparent {
  background-color: transparent; }

.bg-transparent-dark {
  background-color: rgba(0, 0, 0, 0.5) !important; }

.bg-transparent-light {
  background-color: rgba(255, 255, 255, 0.5) !important; }

.font-bold {
  font-weight: 700; }

.font-light {
  font-weight: 300; }

.height-500 {
  height: 500px !important; }

.height-600 {
  height: 600px !important; }

.height-700 {
  height: 700px !important; }

.height-800 {
  height: 800px !important; }

.section-padding-100 {
  padding: 30px 0; }

.section-padding-50 {
  padding: 50px 0; }

.section-padding-50-0 {
  padding: 50px 0 0 0; }

.section-padding-100-70 {
  padding: 100px 0 70px; }

.section-padding-100-50 {
  padding: 20px 0; }
  .section-padding-10-50 {
  padding: 0px 0 50px; }
.section-padding{
    padding: 40px 0 10px;
}
.section-padding-100-0 {
  padding: 50px 0 0; }

.section-padding-0-100 {
  padding: 0 0 100px; }

.section-padding-100-20 {
  padding: 100px 0 20px 0; }
.section-padding-20{
    margin-top: 2rem;
}
.section-padding-40-50{
    background-color: #f1f6f8;
     padding: 40px 0 50px;
}

.bg-fixed {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url(../img/bg-img/cta.jpg);
  background-position: center center;
  background-attachment: fixed;
  background-size: cover; }

.bg-overlay {
  position: relative;
  z-index: 1; }
  .bg-overlay::after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #947054;
    opacity: 0.7;
    content: ''; }

.bg-overlay-black {
  position: relative;
  z-index: 1; }
  .bg-overlay-black::after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.7;
    content: ''; }

.bg-overlay-white {
  position: relative;
  z-index: -1; }
  .bg-overlay-white::after {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(230, 241, 255, 0.5);
    content: ''; }

.gradient-background-overlay:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: #f1f6f8;
    
}

.bg-gray {
  background-color: #f5f7f9; }

.row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.align-items-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -ms-grid-row-align: stretch;
  align-items: stretch; }

.gradient-background-overlay {
  position: relative;
  z-index: 3; }


  

/* :: 6.0 Section Heading Area CSS */
.section-heading {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 100px; }
  .section-heading img {
    margin-bottom: 10px; }
  .section-heading h2 {
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 5px;
    border-bottom: 3px solid #ed2024;
    display: inline-block;
  }
  

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .section-heading h2 {
        font-size: 24px; } }
    
  .section-heading p {
    color: #000;
    font-size: 14px;
    margin-bottom: 0;
    font-style: italic; }

/* :: 7.0 South Button CSS */
.south-btn {
  position: relative;
  z-index: 1;
  max-width: 120px;
  height: 30px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  background-color: #ed2024;
  border-radius: 15px;
  line-height: 30px;
  padding: 0 20px;
  text-transform: uppercase; 
}
  .south-btn.active, .south-btn:hover, .south-btn:focus {
    color: #ffffff;
    background-color: #000000; }

  .south-btn.btn-3 {
    border: 2px solid #000000;
    background-color: transparent;
    color: #000000;
    line-height: 46px; }
    .south-btn.btn-3:hover, .south-btn.btn-3:focus {
      color: #ffffff;
      background-color: #000000; }

 /* :: 9.0 Welcome Area CSS */
.single-hero-slide {
    margin-top: 75px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 100%;
  height: 550px;

}
.banner-section{
    max-width: 100vw;
     height: 550px;
     margin-top: 52px;
}
.banner {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    height: 100%;
  }
  
  .pic1 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 520px;
  }
  .pic1 img{
      width: 100%;
      height: 100%;
      object-fit: fill;
    background-position: center center;
    transform-origin: center center;
  }
  
  
 
 
  .spinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-hero-slide {
      height: 700px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-hero-slide {
      height: 650px; } }
  @media only screen and (max-width: 767px) {
    .single-hero-slide {
      height: 550px; } }

.hero-slides-content {
  text-align: center; }
  .hero-slides-content h2 {
    color: #ffffff;
    font-size: 60px;
    margin-bottom: 0;
    padding: 10px 15px;
    text-transform: uppercase;
    background-color: #000000;
    line-height: 1;
    display: inline-block; 
     
  }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .hero-slides-content h2 {
        font-size: 40px; } }
    @media only screen and (max-width: 767px) {
      .hero-slides-content h2 {
        font-size: 16px; } }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      .hero-slides-content h2 {
        font-size: 24px; } }


.hero-area .owl-next {
  left: auto;
  right: 70px; }
  @media only screen and (max-width: 767px) {
    .hero-area .owl-next {
      left: auto;
      right: 30px; } }

.hero-area .owl-dots {
  width: 1110px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-area .owl-dots {
      width: calc(100% - 6%);
      left: 5%;
      -webkit-transform: translateX(0%);
      transform: translateX(0%); } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-area .owl-dots {
      width: calc(100% - 6%);
      left: 5%;
      -webkit-transform: translateX(0%);
      transform: translateX(0%); } }
  @media only screen and (max-width: 767px) {
    .hero-area .owl-dots {
      width: calc(100% - 30px);
      left: 20px;
      -webkit-transform: translateX(0%);
      transform: translateX(0%); } }

/* :: 11.0 Single Property Area CSS */
.single-featured-property {
  position: relative;
  z-index: 1;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
    background-color: #fff;
    border-radius: 10px;
     box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
  .single-featured-property .property-thumb {
    position: relative;
    z-index: 1; 
      overflow: hidden;
      border-radius: 10px;
    height: 26vh;
  }
    .single-featured-property .property-thumb img{
        width: 100%;
       height: 26vh;
        overflow: hidden;
        object-fit: fill;
    }
    
    .single-featured-property .property-thumb .tag span {
      height: 22px;
      padding: 0 6px;
      background-color: #ed2024;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
      display: inline-block;
      line-height: 22px;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 10;
      border-radius: 10px 0 0 0;
      -webkit-transition-duration: 500ms;
      transition-duration: 500ms; }
      .single-featured-property .property-thumb .list-price{
        background-image: url(../img/bg-img/red.png);
          width: 220px;
          height: 50px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          background-blend-mode: overlay;
          position: absolute;
         bottom: -12px;
         right: -36px;
          -webkit-transition-duration: 500ms;
      transition-duration: 500ms;
     display: flex;
     align-items: center;
     justify-content: center;
      }

    .single-featured-property .property-thumb .list-price p{
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      position: absolute;
      z-index: 10;
      margin-top: 14px;
      -webkit-transition-duration: 500ms;
      transition-duration: 500ms; }
      .single-featured-property .property-thumb .list-price p span{
           font-size: 16px;
        font-weight: 600;
      }
  .single-featured-property .property-content {
    padding: 22px 18px 32px !important;
    margin-top: -10px;
    max-height: 36vh;
      
  }
    .single-featured-property .property-content h5 {
      font-size: 18px; 
        margin-bottom: 2px;
    }
    .single-featured-property .property-content p {
      margin-bottom: 16px; }
    .single-featured-property .property-content .location {
      color: #ed2024;
      margin-bottom: 15px;
      font-size: 14px;
      font-weight: 600; }
      .property-meta-data{
          margin-bottom: 10px;
      }
      .property-meta-data img{
          width: 10px;
          height: 10px;
      }
      .view-location{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
      }
      .view-location a{
          margin-bottom: -55px;
          width: 40px;
          height: 40px;
          text-align: center;
          padding: 8px;
          background-color: #ed2024;
          color: #fff;
          font-size: 16px;
          border-radius: 50%;
          box-shadow: 0 5px 4px rgba(0,0,0,.5);
      }
      .view-location a:hover{
          transform: translate(0, -15%);
      }
      @media(max-width: 800px){
        .single-featured-property .property-content{
          min-height: 1vh;
        }
      }
      
      .location1{
          color: #000 !important;
       text-align: center;
       font-size: 14px !important;
       /*margin-top: -30px;*/
       margin-bottom: 5px;
      }
      .single-featured-property .property-content .location img {
        margin-right: 10px;
        display: inline-block !important;
        width: 16px;
        height: 20px;
      }
    .single-featured-property .property-content .property-meta-data span {
      font-size: 12px;
      font-weight: 600;
      margin-left: 5px;
      color: #000; }
    .single-featured-property .property-content .property-meta-data img {
      margin-right: 10px;
      display: inline-block !important;
      width: auto !important; }
      @media only screen and (max-width: 767px) {
        .single-featured-property .property-content .property-meta-data img {
          margin-right: 0; } }
  .single-featured-property:hover, .single-featured-property:focus {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px; }

/* :: 12.0 Testimonials Area CSS */
.testimonials-slides {
  position: relative;
  z-index: 1; }
  .testimonials-slides .single-testimonial-slide {
    position: relative;
    z-index: 1;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
    .testimonials-slides .single-testimonial-slide h5 {
      margin-bottom: 60px;
      color: #7d7d7d; }
    .testimonials-slides .single-testimonial-slide .testimonial-author-info {
      margin-top: 50px; }
      .testimonials-slides .single-testimonial-slide .testimonial-author-info img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 0 auto 20px; }
      .testimonials-slides .single-testimonial-slide .testimonial-author-info p {
        margin-bottom: 0;
        color: #323232; }
        .testimonials-slides .single-testimonial-slide .testimonial-author-info p span {
          color: #947054; }
  .testimonials-slides .center .single-testimonial-slide {
    -webkit-transform: scale(1);
    transform: scale(1); }
    .testimonials-slides .center .single-testimonial-slide h5 {
      color: #000000; }
  
   
  .testimonials-slides .owl-next {
    left: auto;
    right: -19px; }
    @media only screen and (max-width: 767px) {
      .testimonials-slides .owl-next {
        left: auto;
        right: 0; } }

/* :: 13.0 Editor Area CSS */
.south-editor-area {
  position: relative;
  z-index: 1; 
  
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .south-editor-area {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } 
      .deals{
          height: 10vh;
      }
  }
  @media only screen and (max-width: 767px) {
    .south-editor-area {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }
  .south-editor-area .editor-content-area,
  .south-editor-area .editor-thumbnail {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    min-width: 50%; 
      padding: 20px 5%;
  }
  .border-shadow{
    width: 100%;
    height: 42vh;
    overflow: hidden;
  }
  figure{
      overflow: hidden;
        border: 4px solid #000;
        border-radius: 10px;
        width: 100%;
        height: 100%;

  }
  figure img{
    width: 100%;
    height: 100%;
  }
  .border-shadow figure .band{
    position: relative;
    width: 100%;
    height: 120px;
   top: -80px;
    background-color: #000;
    opacity: 0.6;
    display: none;
  }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .south-editor-area .editor-content-area,
      .south-editor-area .editor-thumbnail {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        min-width: 100%; } }
    @media only screen and (max-width: 767px) {
      .south-editor-area .editor-content-area,
      .south-editor-area .editor-thumbnail {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        min-width: 100%; } }
  .south-editor-area .editor-content-area {
    position: relative;
    z-index: 1;
    padding: 30px 10%; }
    .south-editor-area .editor-content-area .section-heading {
      text-align: left;
      margin-bottom: 10px; }
    .south-editor-area .editor-content-area .address {
      margin-top: 30px; }
      .south-editor-area .editor-content-area .address h6 {
        margin-bottom: 15px;
        font-size: 14px; }
        .south-editor-area .editor-content-area .address h6:last-of-type {
          margin-bottom: 0; }
        .south-editor-area .editor-content-area .address h6 img {
          margin-right: 10px; }
.location2 img{
    width: 16px; 
    height: 16px
}
.south-editor-area .editor-content-area .tag1
{
    width: 120px;
    background-color: #ed2024;
    color: #fff;
    margin: 10px 0;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
}
.south-editor-area .editor-content-area .list-price1 p{
     margin: 10px 0;
     font-size: 24px;
     font-weight: 700;
     color: #ed2024;
}
.south-editor-area .editor-content-area .south-btn{
    height: 40px;
    line-height: 40px;
    min-width: 150px;
}

.south-editor-area .editor-content-area .tag1:hover{
    background-color: #000;
}

.hot-deals{
    margin-top: -35px;
}
/* :: 14.0 Call to Action CSS */
.call-to-action-area {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 650px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .call-to-action-area {
      height: 500px; } }
  @media only screen and (max-width: 767px) {
    .call-to-action-area {
      height: 500px; } }
  .call-to-action-area .cta-content h2 {
    color: #ffffff;
    font-size: 48px;
    margin-bottom: 20px;
    text-transform: uppercase; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .call-to-action-area .cta-content h2 {
        font-size: 40px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .call-to-action-area .cta-content h2 {
        font-size: 30px; } }
    @media only screen and (max-width: 767px) {
      .call-to-action-area .cta-content h2 {
        font-size: 24px; } }
  .call-to-action-area .cta-content h6 {
    font-size: 30px;
    font-style: italic;
    color: #ffffff; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .call-to-action-area .cta-content h6 {
        font-size: 24px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .call-to-action-area .cta-content h6 {
        font-size: 18px; } }
    @media only screen and (max-width: 767px) {
      .call-to-action-area .cta-content h6 {
        font-size: 16px; } }

/* :: 15.0 Footer Area CSS */
.footer-area {
  position: relative;
  z-index: 1;
  overflow-x: hidden; }
  .footer-area .copywrite-text {
    width: 100%;
    height: 50px;
    background-color: #111113;
    padding: 0 15px; }
    .footer-area .copywrite-text p {
      margin-bottom: 0;
      font-size: 12px;
      color: #fff; }
      .footer-area .copywrite-text p a {
        font-size: 12px;
        color: #7d7d7d; }

.footer-widget-area .widget-title {
  margin-bottom: 20px; }
  .title-about{
       margin-bottom: 10px !important;
  }
  .about-first{
     justify-content: center;
  }
  .footer-widget-area .widget-title h6 {
    font-size: 16px;
    color: #000;
    margin-bottom: 0;
    text-transform: uppercase;
    border-bottom: 2px solid;
    border-color: #ed2024;
    line-height: 1.8;
    display: inline-block; }
.footer-widget-area p {
  color: #000;
  margin-bottom: 0;
  font-weight: 600;
text-align: justify;
font-size: 14px;
}
.footer-widget-area .weekly-office-hours ul li {
  padding: 15px 0;
  display: block;
  border-bottom: 1px solid #7d7d7d; }
  .footer-widget-area .weekly-office-hours ul li span {
    color: #7d7d7d;
    font-size: 14px;
    font-weight: 400; }
  .footer-widget-area .weekly-office-hours ul li:first-of-type {
    padding-top: 0; }
.footer-widget-area .address {
  margin-top: 40px; }
  .footer-widget-area .address h6 {
    position: relative;
    z-index: 1;
    color: #000;
    margin-bottom: 20px;
    display: block;
    font-size: 14px;
    font-weight: 400;
    padding-left: 40px;
    line-height: 1.8; }
    .footer-widget-area .address img {
     color: #fff;
      position: absolute;
      top: 4px;
      z-index: 10;
      left: 0; }
.footer-widget-area .useful-links-nav {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; 
  text-align: left !important;
  padding-left: 0;
}
  .footer-widget-area .useful-links-nav li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    min-width: 50%; }
    .footer-widget-area .useful-links-nav li a {
      padding: 15px 0;
      display: block;
      color: #000;
      font-weight: 600; 
    font-size: 14px;
    }
      .footer-widget-area .useful-links-nav li a:first-child, .footer-widget-area .useful-links-nav li a:nth-child(2) {
        padding-top: 0; }
      .footer-widget-area .useful-links-nav li a:hover, .footer-widget-area .useful-links-nav li a:focus {
        color: #ed2024; }
.footer-widget-area .featured-properties-slides {
  position: relative;
  z-index: 1; }
  
  .footer-widget-area .featured-properties-slides .owl-next {
    left: auto;
    right: -19px; }
  
    .social-media{
        display: flex;
        width: 100%;
        height: 40px;
        margin-top: 6px;
    }
    .social-media .facebook,
    .twitter,
    .linkedin,
    .instagram{
         display: flex;
        background-color: #ed2024;
        color: #fff;
        padding: 12px;
         height: 38px;
          width: 38px;
        border-radius: 50%;
        margin: 0 5px;
        box-shadow: 0 5px 4px rgba(0,0,0,.5);
        align-items: center;
    justify-content: center;
    }
 .social-media a:hover{
        background: transparent;
        border: 2px solid #ed2024;
        color: #ed2024;
        transform: translate(0, -10%);
    }
    .social-media a img{
        width: 100%;
        height: 100%;
        object-fit: contain;
         color: #fff;
    }

/* :: 16.0 Breadcumb Area CSS */
.breadcumb-area {
  width: 100%;
  height: 450px;
  position: relative;
  z-index: 1;
  padding-top: 130px;
  text-align: center; }
  .breadcumb-area .breadcumb-title {
    font-size: 60px;
    margin-bottom: 0;
    background-color: #000000;
    padding: 5px 20px 7px;
    line-height: 1;
    color: #ffffff;
    display: inline-block;
    text-transform: uppercase; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .breadcumb-area .breadcumb-title {
        font-size: 48px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .breadcumb-area .breadcumb-title {
        font-size: 36px; } }
    @media only screen and (max-width: 767px) {
      .breadcumb-area .breadcumb-title {
        font-size: 30px; } }

/* :: 17.0 About Us Area CSS */
.about-content-wrapper {
  position: relative;
  z-index: 1;
  overflow-x: hidden; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-content-wrapper .about-content {
      margin-bottom: 100px; } }
  @media only screen and (max-width: 767px) {
    .about-content-wrapper .about-content {
      margin-bottom: 100px; } }
  .about-content-wrapper .about-content img {
    margin-bottom: 40px; }
  .about-content-wrapper .featured-properties-slides {
    position: relative;
    z-index: 1; }
  

/* :: 18.0 Team Member Area CSS */
.single-team-member {
  position: relative;
  z-index: 1; }
  .single-team-member .team-member-thumb {
    margin-bottom: 40px; }
    .single-team-member .team-member-thumb img {
      width: 100%; }
  .single-team-member .team-member-info {
    text-align: center; }
    .single-team-member .team-member-info .section-heading {
      margin-bottom: 40px; }
    .single-team-member .team-member-info .address h6 {
      margin-bottom: 15px;
      font-size: 14px; }
      .single-team-member .team-member-info .address h6:last-of-type {
        margin-bottom: 0; }
      .single-team-member .team-member-info .address h6 img {
        margin-right: 10px; }

/* :: 19.0 Listings Area CSS */
.listings-content-wrapper {
  overflow-x: hidden; }

.listings-top-meta {
  position: relative;
  z-index: 100; }
  .listings-top-meta .nice-select {
    float: right;
    height: 34px;
    line-height: 34px;
    position: relative;
    z-index: 100; }
    .listings-top-meta .nice-select .list {
      position: absolute;
      z-index: 500; }
    .listings-top-meta .nice-select .option {
      min-height: 34px;
      line-height: 34px; }
  .listings-top-meta span {
    color: #323232;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px; }
    @media only screen and (max-width: 767px) {
      .listings-top-meta span {
        font-size: 11px; } }
  .listings-top-meta .grid_view a {
    color: #323232;
    line-height: 1;
    font-size: 18px; }
    .listings-top-meta .grid_view a.active {
      color: #947054; }
  .listings-top-meta .list_view a {
    color: #323232;
    line-height: 1;
    font-size: 18px; }
    .listings-top-meta .list_view a.active {
      color: #947054; }

.single-listings-sliders {
  position: relative;
  z-index: 1; }
  

.listings-content {
  margin-top: 70px; 
}
.listings-content img{
  border-radius: 10px;
  margin-bottom: 15px;
}
.listings-content .img-gallery{
   margin-top: 10px; 
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
}
.listings-content .img-gallery .gallery{
   width: 200px;
   padding: 10px;
  
}
  .listings-content .list-price p {
    margin-bottom: 0;
    font-size: 26px;
    font-weight: 600;
    color: #ed2024; }
  .listings-content h5 {
    font-size: 30px;
    margin: 10px 0; }
    @media only screen and (max-width: 767px) {
      .listings-content h5 {
        font-size: 20px; } }
  .listings-content .location {
    margin-bottom: 10px; }
    .listings-content .location img {
      margin-right: 15px; }
      
   
      .listings-content .description span{
        color: #ed2024;
        font-size: 18px;
        font-weight: 600;
       
}
      
.listings-content .property-meta-data .request-plan button{
  border: none;
  padding: 0px 10px !important;
  line-height: 20px;
  height: 40px;
}
  .listings-content .description p {
    margin-bottom: 10px; 
    margin-left: 40px;
  }
  
  .listings-content .property-meta-data div {
    margin-right: 15px;
  padding: 10px;
  font-size: 20px;
  }
  .listings-content .property-meta-data  .plans-name,
  .aminities .plans-name{
    color: #ed2024;
    margin-right: 80px;
    font-size: 18px;
    font-weight: 600;
  }
  .listings-content .property-meta-data span {
    font-size: 16px;
    font-weight: 600;
    margin-left: 6px;
    color: #000; }
  .listings-content .property-meta-data img {
    margin-right: 10px;
    display: inline-block !important;
    width: auto !important; }
    @media only screen and (max-width: 767px) {
      .listings-content .property-meta-data img {
        margin-right: 0; } }
   .listings-core-features {
    padding: 70px 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; 
   text-align: center;
   justify-content: center;
    margin: 0 20px;
  }
     .listings-core-features li {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20.3333333%;
      flex: 0 0 20.3333333%;
      min-width: 20.3333333%;
      color: #323232;
      font-size: 15px;
      margin: 0px 5px 25px; 
    padding: 30px 0;
    cursor: pointer;
    background-color: #f1f6f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
   
      @media only screen and (max-width: 767px) {
         .listings-core-features li {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          min-width: 100%; } }
      .listings-core-features li i {
        width: 27px;
        height: 27px;
        background-color: #947054;
        color: #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 27px;
        font-size: 12px;
        margin-right: 10px; }
  .listings-content .listings-btn-groups a:first-child {
    margin-bottom: 15px; }
  .listings-content .listings-btn-groups .south-btn {
    min-width: 285px;
    width: 285px;
    display: block; }

.contact-realtor-wrapper {
  background-color: #f5f5f5;
  margin-top: 20px;
  position: relative;
  z-index: 1; }
  .contact-realtor-wrapper .realtor-info > img {
    width: 100%; }
  .contact-realtor-wrapper .realtor---info {
    padding: 20px 35px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .contact-realtor-wrapper .realtor---info {
        padding: 40px 30px; } }
    @media only screen and (max-width: 767px) {
      .contact-realtor-wrapper .realtor---info {
        padding: 20px; } }
    .contact-realtor-wrapper .realtor---info h2 {
      font-size: 24px;
      text-transform: uppercase; }
    .contact-realtor-wrapper .realtor---info p {
      font-style: italic;
      color: #947054; }
    .contact-realtor-wrapper .realtor---info h6 {
      font-size: 14px; }
      .contact-realtor-wrapper .realtor---info h6 img {
        margin-right: 15px; }
  .contact-realtor-wrapper .realtor--contact-form {
    padding: 0 35px 20px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .contact-realtor-wrapper .realtor--contact-form {
        padding: 0 30px 40px; } }
    @media only screen and (max-width: 767px) {
      .contact-realtor-wrapper .realtor--contact-form {
        padding: 0 20px 20px; } }
    .contact-realtor-wrapper .realtor--contact-form .form-group {
      margin-bottom: 10px; }
    .contact-realtor-wrapper .realtor--contact-form .form-control {
      border: 1px solid #e1dddd;
      height: 36px;
      background-color: #ffffff;
      border-radius: 0;
      font-style: italic;
      font-size: 12px;
      margin-bottom: 15px; }
    .contact-realtor-wrapper .realtor--contact-form textarea.form-control {
      height: 100px;
      padding: 15px; }

.listings-maps {
  position: relative;
  z-index: 1; }
  .listings-maps #googleMap {
    width: 100%;
    height: 540px; }

/* :: 20.0 Blog Area CSS */
.single-blog-area {
  position: relative;
  z-index: 1; }
  .single-blog-area .blog-post-thumbnail {
    position: relative;
    z-index: 1; }
    .single-blog-area .blog-post-thumbnail img{
      border-radius: 10px;
      border: 4px solid #000;
    }
  .single-blog-area .post-content {
    position: relative;
    z-index: 1;
    padding: 50px 0; }
    .single-blog-area .post-content .post-date a {
      font-size: 14px;
      color: #947054;
      margin-bottom: 10px;
      display: block; }
    .single-blog-area .post-content .headline {
      font-size: 30px;
      color: #323232;
      display: block; }
    .single-blog-area .post-content .post-meta {
      position: relative;
      margin-bottom: 30px; }
      .single-blog-area .post-content .post-meta p {
        color: #aaa8a8;
        font-size: 12px; }
        .single-blog-area .post-content .post-meta p a {
          font-size: 12px;
          color: #aaa8a8;
          display: inline-block; }
    .single-blog-area .post-content p {
      margin-bottom: 50px; }

.search-widget-area form {
  position: relative;
  z-index: 1; }
.search-widget-area input[type=search] {
  width: 100%;
  height: 43px;
  border: 1px solid #e1dddd;
  font-size: 12px;
  font-style: italic;
  padding: 0 30px; }
.search-widget-area button[type=submit] {
  width: 50px;
  height: 43px;
  background-color: transparent;
  border: none;
  font-size: 14px;
  color: #7e7e7e;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer; }

.south-catagories-card h5 {
  margin-bottom: 30px;
  font-size: 16px; }
.south-catagories-card ul li a {
  display: block;
  padding: 8px 0;
  color: #7d7d7d;
  font-size: 14px;
  font-weight: 400; }
  .south-catagories-card ul li a:hover, .south-catagories-card ul li a:focus {
    color: #947054; }

.south-blog-area {
  position: relative;
  z-index: 1;
  overflow-x: hidden; }

.blog-sidebar-area .featured-properties-slides {
  position: relative;
  z-index: 1; }
  

.pagination .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }
.pagination .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }
.pagination .page-item.active .page-link {
  color: #ffffff;
  background-color: #323232; }
.pagination .page-link {
  padding: 6px;
  margin-left: 3px;
  line-height: 20px;
  color: #323232;
  border: none;
  width: 30px;
  height: 30px;
  background-color: #ed2024;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 5px; }
  .pagination .page-link.active {
    background-color: #000000; }
  .pagination .page-link:focus, .pagination .page-link:hover {
    color: #ffffff;
    background-color: #000000; }

.comments-area h5 {
  margin-bottom: 50px; }

.single_comment_area {
  margin-bottom: 50px; }
  .single_comment_area .comment-wrapper .comment-author {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
    min-width: 60px;
    border-radius: 50%;
    margin-right: 30px; }
    .single_comment_area .comment-wrapper .comment-author img {
      border-radius: 50%; }
    @media only screen and (max-width: 767px) {
      .single_comment_area .comment-wrapper .comment-author {
        margin-right: 10px; } }
  .single_comment_area .comment-wrapper .comment-content .comment-meta a {
    display: inline-block;
    color: #323232;
    font-size: 12px;
    margin-bottom: 15px;
    text-transform: uppercase; }
    .single_comment_area .comment-wrapper .comment-content .comment-meta a:hover {
      color: #947054; }
  .single_comment_area .comment-wrapper .comment-content p {
    font-size: 13px;
    margin-bottom: 0; }
  .single_comment_area .children {
    margin-top: 50px;
    padding-left: 90px; }
    @media only screen and (max-width: 767px) {
      .single_comment_area .children {
        padding-left: 30px; } }

.leave-comment-area .comment-form h5,
.leave-comment-area .contact-form h5,
.south-contact-area .comment-form h5,
.south-contact-area .contact-form h5 {
  margin-bottom: 50px; }
.leave-comment-area .comment-form .form-group,
.leave-comment-area .contact-form .form-group,
.south-contact-area .comment-form .form-group,
.south-contact-area .contact-form .form-group {
  margin-bottom: 10px; }
.leave-comment-area .comment-form .form-control,
.leave-comment-area .contact-form .form-control,
.south-contact-area .comment-form .form-control,
.south-contact-area .contact-form .form-control {
  width: 100%;
  height: 43px;
  background-color: transparent;
  padding: 15px;
  border: 1px solid #e1dddd;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 0 !important; }
.leave-comment-area .comment-form textarea.form-control,
.leave-comment-area .contact-form textarea.form-control,
.south-contact-area .comment-form textarea.form-control,
.south-contact-area .contact-form textarea.form-control {
  height: 200px !important; }
.leave-comment-area .comment-form button[type=submit],
.leave-comment-area .contact-form button[type=submit],
.south-contact-area .comment-form button[type=submit],
.south-contact-area .contact-form button[type=submit] {
  margin-top: 40px; }
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .leave-comment-area .comment-form,
  .leave-comment-area .contact-form,
  .south-contact-area .comment-form,
  .south-contact-area .contact-form {
    margin-bottom: 50px; } }
@media only screen and (max-width: 767px) {
  .leave-comment-area .comment-form,
  .leave-comment-area .contact-form,
  .south-contact-area .comment-form,
  .south-contact-area .contact-form {
    margin-bottom: 40px; } }

/* :: 21.0 Contact Area CSS */
.contact-heading {
  margin-bottom: 90px; }
  .contact-heading h6 {
    border-bottom: 2px solid #947054;
    display: inline-block;
    padding: 5px 0;
    text-transform: uppercase; }

.content-sidebar .weekly-office-hours ul li {
  padding: 15px 0;
  display: block;
  border-bottom: 1px solid #f8f7f7; }
  .content-sidebar .weekly-office-hours ul li span {
    color: #7d7d7d;
    font-size: 14px;
    font-weight: 400; }
  .content-sidebar .weekly-office-hours ul li:first-of-type {
    padding-top: 0; }
.content-sidebar .address {
  margin-top: 40px; }
  .content-sidebar .address h6 {
    position: relative;
    z-index: 1;
    color: #7d7d7d;
    margin-bottom: 20px;
    display: block;
    font-size: 14px;
    padding-left: 40px;
    line-height: 1.8; }
    .content-sidebar .address h6 img {
      position: absolute;
      top: 4px;
      z-index: 10;
      left: 0; }
@media (max-width: 992px){
  .content-sidebar .address{
    margin: 20px 0;
    display: flex;
    flex-direction: column;
   justify-content: left;
  }
  .content-sidebar .address h6{
    padding-left: 0;
  }
}
.googleMap {
  width: 100%;
  height: 540px; }

/* :: 22.0 Shortcode Area CSS */
.elements-title h2 {
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 100px; }

/* Accordians */
.single-accordion.panel {
  background-color: #ffffff;
  border: 0 solid transparent;
  border-radius: 4px;
  box-shadow: 0 0 0 transparent;
  margin-bottom: 15px; }
.single-accordion:last-of-type {
  margin-bottom: 0; }
.single-accordion h6 {
  margin-bottom: 0;
  text-transform: uppercase; }
  .single-accordion h6 a {
    background-color: #947054;
    border-radius: 0;
    color: #ffffff;
    display: block;
    margin: 0;
    padding: 15px 60px 15px 15px;
    position: relative;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
    border: 2px solid transparent; }
    .single-accordion h6 a span {
      font-size: 10px;
      position: absolute;
      right: 20px;
      text-align: center;
      top: 18px; }
      .single-accordion h6 a span.accor-open {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0); }
    .single-accordion h6 a.collapsed {
      border: 2px solid #947054;
      background-color: transparent;
      color: #000000; }
      .single-accordion h6 a.collapsed span.accor-close {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0); }
      .single-accordion h6 a.collapsed span.accor-open {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100); }
.single-accordion .accordion-content {
  border-top: 0 solid transparent;
  box-shadow: none; }
  .single-accordion .accordion-content p {
    padding: 20px 15px 5px;
    margin-bottom: 0; }


/* Loaders CSS */
.single-pie-bar {
  position: relative;
  z-index: 1; }
  .single-pie-bar h5 {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 0; }

/* Cool Facts CSS */
.single-cool-fact-area h2 {
  font-size: 60px;
  margin: 15px 0;
  color: #947054; }
.single-cool-fact-area h6 {
  margin-bottom: 0;
  color: #7d7d7d; }

.builder{
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    padding: 25px 0 !important;
}
.builder-slider{
    width: 12vw;
    height: 9vh;
    margin: 0 1rem;
    border: 2px solid black;
    border-radius: 5px;
    padding: 10px;
    animation: animate 12s alternate-reverse linear infinite;
}

.builder-slider img{
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    border-radius: 0 !important;
}
.brands{
    width: 1000px;
    display: flex;
    margin: 0 auto !important;
    overflow: hidden;
}
.brands:hover .builder-slider{
    animation-play-state: paused;
}
@keyframes animate{
    0%{
        transform: transLate3d(0, 0, 0);
    }
    100%{
        transform: transLate3d(-1100px, 0, 0);
    }
} 
@media(max-width: 750px){
    .brands{
        width: 95%;
    }
}


.media,
.events
{
  display: flex;
  flex-wrap: wrap;
  display: none;
}

.events .single-featured-property .property-thumb{
  height: 55vh;
  border-radius: 10px;
}
.events .single-featured-property .property-thumb img{
  height: 100%;
  border-radius: 10px;
}

.events .single-featured-property .property-thumb .shadow{
  width: 100%;
  height: 55vh;
  background-color: rgb(237, 32, 36, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  transform: translateY(100%);
  transition: all 0.5s ease;
}
.events .single-featured-property .property-thumb:hover .shadow{
  transform: translateY(0%);
}
.events .events-heading{
  position: absolute;
  z-index: 9999;
  color: #fff;
  top: 10px;
  left: 15px;
  font-size: 18px;
}
.events .event-name{
  position: absolute;
  z-index: 9999;
  color: #fff;
  top: 40px; 
  left: 15px;
  font-size: 16px;
  font-family: 400;
}
.events .date{
  width: 100%;
  position: absolute;
  z-index: 9999;
  color: #fff;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  font-size: 16px;
}
.section-heading h2 span{
  cursor: pointer;
}
.active-tab{
  display: flex;
  justify-content: center;
}
.active-color{
  color: #ed2024;
}
.single-testimonial-slide{
  margin: 1rem .5rem;
}
.single-testimonial-slide p{
  margin-top: 2.5rem;
}
.single-testimonial-slide img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.featured{
  height: 550px;
}

.featured h2,
.banner h2{
  position: absolute;
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 6px 80px;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: 1.5px;
}
.featured .mb-100{
  margin-bottom: 30px;
}

.client .property-content{
  border-top: 5px solid #ed2024;
  text-align: center;
  padding-top: 35px;
  background-color: #f1f6f8;
  border-radius: 0 0 10px 10px;
}
.client .property-content h5{
  color: #ed2024;
}
 .client-section span,
 .quotes {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  color: #ed2024;
  content: "\f10e";
  font-size: 32px;
  font-style: normal;
  left: 50%;
  line-height: 60px;
  position: absolute;
  top: -34px;
  width: 60px;
  z-index: 999;
  text-align: center;
}

.contact-form h3{
  text-align: center;
  margin-bottom: 2rem;
}
.content-sidebar .address a{
  color: #000;
  font-size: 16px;
  font-weight: 500;

}
.content-sidebar .address{
  margin-top: 70px;
}
.content-sidebar .address .address-list{
  display: flex;
  margin-top: 4px;
}
.content-sidebar .address .address-list span{
  margin-right: 15px;
  color: #ed2024;
  font-size: 18px;
  
}
.content-sidebar .address .social-media{
  margin-left: 30px;
  
}
 .social-media .facebook,
.twitter, .linkedin, .instagram{
  color: #fff !important;
}
 .social-media a:hover{
  color: #ed2024 !important;
}
.scroll{
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ed2024;
  color: #fff;
  font-size: 22px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.scroll:hover{
  background-color: #000;
}
.scroll.visible {
  display: block;
}
.builder-banner{
  width: 60%;
  height: 18vh;
  margin: auto;
  margin-top: 90px;
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
}
.builder-banner img{
  width: 100%;
  height: 100% !important;
  border-radius: 10px;
}
.top-rated{
  padding: 20px;
  margin-top: 25px;
  background-color: #f5f5f5;
}

.top-rated ul li{
  list-style-type: disc;
}

.feature-details{
  margin: 80px 0 -70px 15px;
}

.amenities{
  justify-content: space-between;
  flex-wrap: wrap;
}


.about-section{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.about-section p{
  width: 60%;
  
}
.about p{
  text-align: justify;
}
.about p h3,
strong{
  color: #ed2024;
}
.about-section .about-img{
  width: 35%;
  height: 30vh;
  padding: 20px 30px;
  border: 4px solid #000;
}
.about-section .about-img img{
  
}
.mission{
  background-color: #f1f6f8;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.mission  p{
  width: 50%;
  padding: 20px 40px;
  margin-right: 30px;
}
.mission .mission-img{
  width: 32%;
  height: 60vh;
  margin: 30px 10px 30px 0;
}
.mission .mission-img img{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.mission .mission-icon{
  font-size: 40px;
  margin-bottom: 10px;
}
.vission .mission-img{
  margin: 30px 0px 30px 0;
}
.about p strong{
  font-size: 18px;
}
.about-detail{
  margin: 0 15px;
}
.about .about-area{
  display: flex;
  justify-content: space-between;
  margin: 35px 30px 0;
  text-transform: uppercase;
}

.about .about-area p{
  font-size: 22px;
  font-weight: 600;
  color: #ed2024;
}


.blog-details{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.blog-details .right{
  width: 65%;
  border-radius: 10px;
}
.blog-details .right img{
  border-radius: 10px;
  height: 60vh;
}
.blogdetails{
  padding: 20px 30px;
}
.blog-details .left{
  width: 32%;
  padding: 20px;
  height: 400px;
  overflow-y: scroll;
}
.blog-details .left hr{
  margin: 0;
}
.blog-details .right span{
  color: #ed2024;
}

.blog-side{
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
}
.blog-side img{
  max-width: 120px;
  border-radius: 10px;
  max-height: 60px;
  margin-right: 20px;
}
.left h4{
  color: #ed2024;
}
.left h5:hover{
  color: #ed2024;
}
.view-area span a{
  color: #ed2024;
}
.blogdetails .view-area{
  margin-top: 70px;
  margin-bottom: 20px;
 
}
.view-area{
  background-color: #f1f6f8;
  padding: 7px;
}
.view-area span,
a{
  font-size: 12px;
}


.filter-btns{
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.filter-btns span {
  position: relative;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  touch-action: manipulation;
  margin: -15px 8px 25px;
  font-weight: 600;
 }
 .filter-btns span a{
  color: #000;
  font-size: 14px; 
 }

 .filter-btns .active{
  background:  #046ba8;
  color: #fbfdff;
  border-radius: 18px;
  padding: 1px 14px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
 }
 .filter-btns .active:nth-child(1){
  padding: 1px 18px;
 }
.request{
  text-transform: uppercase;
  display: inline-block;
  padding: 8px 10px;
  text-align: center;
  font-size: 12px;
  text-decoration: none;
  color: #fff;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 2px solid #25D366;
  border-radius: 10px;
  background-color: #25D366;
}
.request:hover{
  color: #fff;
}
.continue{
  position: absolute;
  right: 30px;
  top: 85px;
}
.overview-slide{
  height: 350px;
  margin-bottom: 10px;
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
 
    max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

  .row{
    padding: 0 20px !important;
  }

.client .property-content p{
  color: #000 !important;
  text-align: justify;
  line-height: 1.7;
}
.carousel-item img{
  width: 100%;
  height: 100%;
  background-size: cover;
 padding: 0;
}
.realtor---info li{
  list-style: none;
  
}
.realtor---info li a{
  color: #000;
}
.top-rated {
  width: 100%;
  min-height: 154px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f1f6f8;
  border-radius: 40px 0 40px 0;
  margin-top: 45px;
 }
.top-rated h4{
  color: #ed2024;
}
.top-rated .usp{
  position: absolute;
  border: 2px dotted gray;
  padding: 0 40px;
  top: -20px;
  right: 35%;
  background: #f1f6f8;
  border-radius: 40px;
  text-align: center;
}
.top-rated  ul li{
  margin-top: 8px;
}

.aminities{
  display: flex;
  justify-content: space-evenly;
}
 .aminities .plans-name{
  margin: 0;
  width: 34%;
}
 .aminities-list{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
 .aminities-list span{
  font-size: 14px;
  margin: 5px 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.plan-icon{
  margin-right: 10px;
}
.aminities-list img{
  width: 25px;
  height: 25px; 
  margin-right: 10px;
}
.request-plan .south-btn{
  max-width: 170px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}


.swiper-button-next:after, 
.swiper-button-prev:after{
  color: #fff;
}
.listing{
  justify-content: space-between;
  flex-wrap: wrap;
}
.listing .list-price{
  border-radius: .25rem;
  text-transform: uppercase;
  font-style: normal;
  padding-left: 4px;
  padding-right: 4px;
  color: #fff;
  -webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  height: 30px;
  line-height: 10px;
  transition: .2s .1s;
  background-image: linear-gradient(90deg,#363636, #ed2024);
  border: 0 solid;
  overflow: hidden;
  }
  .highlight p,
  .highlight span{
    color: #ffffff !important;
  }
  
  .gallery-section{
    margin-top: 80px;
    padding: 40px 60px;
  }
  .privacy  ul li{
    list-style: disc;
  }
  .overview{
    padding: 15px;
    margin-top: 10px;
  
  }
  .overview h5,
  .overview-amenities h5{
    font-size: 22px;
  }
  .overview-part{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-bottom: 10px;
    height: auto;
  }
  .overview-part table{
    width: 100%;
    border-collapse: collapse;
  }
  tr {
    padding: 10px 0;
  }
  
  th, td {
    text-align: center;
    padding: 10px;
  }
  tr:nth-child(even) {
    background-color: #e5e3e3;;
  }
  .overview-amenities {
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .floorPlan{
    margin-top: 20px;
    padding: 20px;
  }
  .floorPlan .floor-img-area{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .floorPlan .floor-img-area .floor-img{
    width: 35%;
    height: 45%;
  }
  .client .property-content.client_testimonial{
    height: 50vh !important;
  }
 
  input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
.assu,
.pre{
  color: #ed2024;
  margin-left: 5px;
  display: none;
}
.assured:hover .assu{
 display: initial;
}    
.preleased:hover .pre{
  display: initial;
}
.residential-area .space{
  color: #000 !important;
  border: 2px solid green;
}
.main-footer-area .row{
  justify-content: space-around;
}
@media (max-width: 1100px){
  .blog-details{
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .blog-details .right{
    width: 80%;
  }
  .blog-details .right img{
   height: 85vh;
  }
  .blog-details .left{
    width: 80%;
    margin-top: 20px;
  }
}
@media(max-width: 900px){
  .mission{
    flex-direction: column;
  }
  .mission  p,
  .about p{
    width: 100%;
    padding: 20px 40px;
  }
  .about-section .about-img{
    width: 100%;
    height: 100%;
    padding: 0;
    object-fit: contain;

  }
  .mission .mission-img{
    width: 100%;
    height: 50vh;
    margin: 0px 0px 30px 0px;
    overflow: hidden;
    margin-left: -30px;
  }
  .about-section{
    flex-direction: column;
  }
  .section-padding-100-50.about{
    margin-top: -40px;
  }
}
@media (max-width: 780px){
  
  .south-editor-area .editor-content-area {
   position: absolute;
   
  }
   .south-editor-area .editor-thumbnail{
    padding: 20px 5%;
  }
  .south-editor-area .editor-content-area .deals-description,
  .south-editor-area .editor-content-area .south-btn,
  .hot-deals .editor-content-area .space{
    display: none;
  }
  .hot-deals .editor-content-area{
    bottom: 0px;
    display: flex;
    justify-content: space-between;
  }
   .south-editor-area .editor-content-area .section-heading{
    /* display: flex !important; */
    align-items: center;
    justify-content: center;
  }
  .hot-deals .editor-content-area h2,
  .hot-deals .editor-content-area .space,
  .hot-deals .editor-content-area p,
  .hot-deals .editor-content-area .list-price1{
    color: #ffffff !important;
    font-weight: bold;
    margin-left: 2px;
  }
  .hot-deals .editor-content-area .list-price1{
    margin-right: 2px;
  }
  .south-editor-area .editor-content-area .section-heading{
    margin-bottom: -8px;
  }
  .border-shadow figure .band{
    display: block;
  }
  .aminities{
    flex-direction: column;
    justify-content: center ;
  align-items: center !important;
}
 
 .aminities .plans-name{
  margin: 0;
  width: 100%;
}
.blog-details,
.blogdetails{
  padding: 20px 10px;
}
.blog-details .right,
.blog-details .left{
  width: 100%;
}
.blog-details .right img{
  height: 50vh;
}
  .description,
  .details-area{
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
  }
  .listings-content .description p{
    margin-left: 0;
    margin-top: 10px;
  }
  .details-area .property-meta-data div{
    margin-right: 0 !important;
  }
  .listings-content .property-meta-data .plans-name{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
  }
  .amenities{
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .builder-banner{
    margin-top: 20px;
  }
.project_details h5{
    font-size: 24px;
  }
  .listings-content .description span{
    width: 100%;
    text-align: left;
  }
    .floor-plans{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
    }
}

@media screen and (max-width: 600px) {
  .overview h5,
.overview-amenities h5{
  font-size: 18px;
}
table tr {
font-size: 12px;
}
.client .property-content{
height: 40vh;
}
.view-area{
padding: 4px;
margin-right: 6px;
}
.view-area span, a{
font-size: 10px;
}
.listings-top-meta span{
font-size: 9px;
}
.carousel{
height: 30vh;
}
.carousel-inner,
.carousel-item.active{
height: 28vh;
}
.testimonial .section-heading{
margin-bottom: 10px;
}
.testimonial .heading-row{
height: 8vh;
}
.content-sidebar .address{
margin-top: 30px;
}
}

@media(max-width: 480px){
    .aminities{
      flex-direction: column;
      justify-content: center ;
    align-items: center !important;
    }
   .aminities-list2{
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 25px;
   }
   .aminities-list2 span{
    width: 100% !important;
    text-align: left;
   }
   .section-heading h2{
    font-size: 22px;
  
   }
   .heading-row.row{
    padding: 0 0px !important;
   }
   .testimonial{
    padding: 45px 0 0;
   }
   .builder{
    padding-top: 20px;
   }
   .builder .single-testimonial-slide{
    margin: 1rem .5rem;
   }
   .footer-logo{
    position: relative;
    left: -32px;
   }
    .top-rated .usp{
      right: 28%;
    }
      .phone-call{
        display: none;
      }
      .last-container{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }
      .south-editor-area.section-padding{
        padding: 20px 0 10px;
      }
    
        .builder-slider{
          width: 34vw !important;
          height: 8vh !important;
          padding: 5px;
        }
        .builder-slider img{
          width: 32vw !important;
          height: 6vh !important;
          overflow: hidden;
      }

        .pic1{
          height: 220px;
        }
        .pic1 img{
          object-fit: contain;
        }
        .banner-section{
          height: 220px;
        }
        .view-location .location{
          width: 100%;
          text-align: left !important;
        }
      
          .continue{
            position: relative;
           top: 0;
           right: 0;
          }
          .request{
            padding: 6px 8px;
          }
          
          .featured{
            height: 260px;
            margin: 0 0 -20px 0;
          }
          .featured .banner,
          .featured .pic1{
            height: 260px;
          }
          .about-detail{
            margin: 0;
          }
          .about .about-area{
            flex-wrap: wrap;
            margin-top: -30px;
          }
          
          .about .about-area p{
            height: 8vh;
            width: 100px;
            display: flex;
            align-items: center;
            padding: 0;
            line-height: 0;
            margin-bottom: 0;
          }
          .about .about-area .years{
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-top: 10px;
          }
          .about .about-area .tags{
            height: 8vh;
            width: 190px;
            display: flex;
            align-items: center;
          }
          .featured h2, .banner h2{
            font-size: 16px;
            padding: 6px 10px;
            letter-spacing: 1px;
          }
  
            .south-editor-area .editor-thumbnail{
              padding: 20px 6%;
              height: 58vh;
          
            }
            .south-editor-area .editor-thumbnail figure,
            .south-editor-area .editor-thumbnail figure img,
            .border-shadow{
              height: 100% !important;
            }
            .hot-deals .editor-content-area{
             /* flex-direction: column; */
             margin-bottom: 4px;
             
            }
            
            .hot-deals .editor-content-area h2{
              font-size: 16px !important;
            }
            .hot-deals .editor-content-area .list-price1 p{
              margin: 0;
              font-size: 16px !important;
              margin-bottom: 0;
              margin-right: 2px;
            }
          
          
            .south-editor-area .editor-content-area .section-heading{
              display: flex;
              align-items: center;
              flex-direction: column;
            }
            .south-editor-area .editor-content-area{
              padding: 30px 20px;
            }
            .filter-btns{
              margin-top: 5px;
              display: inline-block;
            
             }
             .filter-btns span{
              font-size: 14px;
          
             }
             .featured h2{
              font-size: 20px;
              padding: 6px 14px;
             }
        }
        @media (max-width: 450px){
          .amenities{
            flex-direction: column;
          }
        }
    
        @media (max-width: 360px){
          .pic1{
            height: 206px;
          }
          .pic1 img{
            object-fit: contain;
          }
          .banner-section{
            height: 206px;
          }
      
        }
        @media (max-width: 340px){
          .pic1{
            height: 200px;
          }
          .pic1 img{
            object-fit: contain;
          }
          .banner-section{
            height: 200px;
          }
      
        }