@import url(../components/style.css);

.wrapper{
    background: #fff;
    position: fixed;
    width: 100%;
    height: 75px;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    text-transform: uppercase;
  }
  .wrapper .logo{
    width: 14vw;
    height: 75px;
  }
  .wrapper .logo img{
    width: 100%;
    height: 100%;
  }
 .wrapper  .navbar ul li{
    position: relative;
    float: left;
    padding: 15px;
    cursor: pointer;
    font-size: 14px;
    font-family: 600;
  }
  .wrapper  .navbar ul li a{
    font-size: 14px;
    font-weight: 600;
    display: block;
    color: #000;
  }
  .wrapper  .navbar ul li a:hover{
    color: #ed2024;
    
  }
  .wrapper .navbar ul li ul{
    position: absolute;
    left: 0;
    margin-top: 15px;
    width: 300px;
    background-color: #f1f6f8;
    display: none;
    padding-left: 0;
    max-height: 300px;
    overflow-y: auto;
  }
  .wrapper .navbar ul li ul::-webkit-scrollbar {
    width: 8px;  
  }
  .wrapper .navbar ul li ul::-webkit-scrollbar-thumb {
    background-color: #a4a4a4; 
  }
  .wrapper .navbar ul li ul li{
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px;

  }

  .wrapper .navbar ul li ul li a{
    font-size: 14px;
    font-weight: 600;
  }
  .wrapper .navbar ul li ul li a:hover{
    color: #ed2024;
  }
  .wrapper .navbar ul li:hover > ul{
    display: initial;
  }
.menu-button-container{
  display: none;
  height: 75px;
  width: 30px;
  margin-top: 70px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-bar{
  display: none;
}
.menu-button,
.menu-button::before,
.menu-button::after{
  display: block;
  background-color: #000;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}
.menu-button::before{
  content: '';
  margin-top: -8px;
  background-color: #000;
}
.menu-button::after{
  content: '';
  margin-top: 8px;
  background-color: #000;
}
#menu-bar:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-bar:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}
#menu-bar:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}
.wrapper label{
  font-size: 18px;
  cursor: pointer;
  display: none;
}

@media (max-width: 750px){
  .wrapper .logo{
    width: 16vw;
    height: 75px;
  }
  .wrapper .logo img{
    width: 100%;
  }
}
  @media (max-width: 1100px){
    .wrapper label{
      display: initial;
      font-size: 28px;
      margin-top: 84px;
      margin-right: 6px;
    }
    .wrapper .navbar{
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #fff;
      width: 350px;
      height: 100vh;
      display: none;
    }
    .wrapper .navbar ul li {
      width: 100%;
      height: 100%;
      padding: 10px 0;
    }
    .wrapper .navbar ul li ul{
      position: relative;
      width: 100%;
      max-height: 300px;
      overflow-y: scroll;
    }
    .wrapper .navbar ul li ul li{
      background-color: #eee;
    }
    
    .menu-button-container{
      display: flex;
    }
    #menu-bar:checked ~ .navbar{
      display: initial;
     
    }
    .search-area {
      width: 100% !important;
      margin: 20px 0;
    }
    .searchIcon{
      width: 100% !important;
      height: 50px !important;
      justify-content: right !important;
      padding-right: 20px !important;
    }
  }
  .searchIcon{
    cursor: pointer;
    width: 80px;
    height: 75px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
  }
  .searchIcon:hover{
    color: #000;
    background-color: #ed2024;
  }
  .search-box{
    background-color: #ed2024;
  }
    .search-area {
    margin-right: 1.5rem;
    padding: 0 15px;
    width: 650px;
    display: flex;
    align-items: center;
  }
  .search-area input{
    border: none;
    background: transparent;
    border-radius: 24px;
   line-height: 2.5;
   padding: 0 10px;
   width: 100%;
  }
  .search-icon{
   margin-left: -40px;
  }
  .showSearch{
    display: none;
  }
  @media (max-width: 450px){
    .wrapper{
      height: 65px;
    }
    .wrapper .logo{
      width: 33vw !important;
      height: 65px;
      
    }
    .wrapper{
      padding: 0 .8rem;
    }
    .wrapper .logo img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .wrapper .navbar{
      width: 75%;
    }
  }
  .navbar ul li a.active {
    color: #ed2024 !important;
  }
  